<script setup lang="ts">
const photos = [
	'/main-slider/11.webp',
	'/main-slider/12.webp',
	'/main-slider/4.webp',
	'/main-slider/5.webp',
	'/main-slider/6.webp',
	'/main-slider/8.webp'
]
</script>

<template>
	<div class="slider wrapper block">
		<UISliderMultiply :photos="photos">
			<template #action>
				<v-btn append-icon="mdi-chevron-right" href="/gallery">Смотреть галлерею</v-btn>
			</template>
		</UISliderMultiply>
	</div>
	
</template>

<style scoped lang="scss">
	.slider__btn {
		margin-top: 32px;
	}
</style>