<script setup lang="ts">
const initial = ref({
	y: 20,
	opacity: 0,
})

const enter = ref({
	y: 0,
	opacity: 1,
	transition: {
		type: 'spring',
		stiffness: 250,
		damping: 25,
		mass: 0.5,
		duration: 300,
		delay: 80
	},
})

</script>
<template>
	<section
		v-motion
		:initial="initial"
		:visibleOnce="enter"
	>
		<slot/>
	</section>
</template>

<style scoped lang="scss">

</style>
