<script setup lang="ts">

</script>

<template>
	<div class="location block wrapper">
		<VintersectAnimation class="location__map map block">
			<div class="map__info">
				<div class="map__content">
					<nuxt-icon name="main-page/location" class="adv__icon" filled/>
					<h3 class="map__title">Расположение</h3>
					<h4>г. Сочи, Цурюпы 13Д</h4>
					<v-btn href="https://yandex.ru/maps/-/CDB-RQNI" variant="tonal" prepend-icon="mdi-map-marker-radius-outline">Проложить маршрут</v-btn>
					<p class="map__text">
						Дом расположен в самом центре Сочи. Парки, социальная и образовательная инфраструктура рядом с домом. В шаговой доступности находятся лицей №23, детский сад №17, гипермаркеты, Spa-центры и фитнес-центры.
					</p>
				</div>
			
			</div>
			<v-lazy class="map__lazy" transition="fade-transition">
				<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A1468e0e0063841575df288efd73f79ecebb4be58d6a1e881dcaa5f6dc78b3930&amp;source=constructor" class="map__frame" frameborder="0"></iframe>
			</v-lazy>
		</VintersectAnimation>
		<h3 class="location__title  mt-8">
			Все нужное рядом
		</h3>
		<div class="location__block">
			<v-card class="location__item card">
				<v-img
					src="/main-page/airport.jpg"
					class="align-end"
					gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
					height="200px"
					cover
				>
					<v-card-title class="text-white card__title">
						Аэропорт
					</v-card-title>
					<v-card-subtitle class="text-white card__subtitle">
						30 минут на автомобиле
					</v-card-subtitle>
				</v-img>
			</v-card>
			<v-card class="location__item card">
				<v-img
					src="/main-page/beach.jpg"
					class="align-end"
					gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
					height="200px"
					cover
				>
					<v-card-title class="text-white card__title">
						Пляж Ривьера
					</v-card-title>
					<v-card-subtitle class="text-white card__subtitle">
						7 минут пешком
					</v-card-subtitle>
				</v-img>
			</v-card>
			<v-card class="location__item card">
				<v-img
					src="/main-page/ski.jpg"
					class="align-end"
					gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
					height="200px"
					cover
				>
					<v-card-title class="text-white card__title">
						Красная поляна
					</v-card-title>
					<v-card-subtitle class="text-white card__subtitle">
						45 минут на автомобиле
					</v-card-subtitle>
				</v-img>
			</v-card>
		</div>
		
		
	</div>

</template>

<style scoped lang="scss">
	.map__frame {
		width: 100%;
		height: 400px;
		border-radius: 12px;
		@media (max-width: 900px) {
			margin-top: 0 !important;
		}
		@media (max-width: 500px) {
			margin-top: 0 !important;
			height: 300px !important;
		}
		margin-top: 16px;
	}

	.card {
		&__title {
			font-size: 32px;
			font-family: 'CasperBold', sans-serif;
		}
		
		&__subtitle {
			font-size: 16px;
			margin-bottom: 16px;
		}
	}
	
	.map {
		margin-top: 32px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		
		@media screen and (max-width: 860px) {
			display: block;
			margin-top: 0;
			&__content, &__frame {
				margin-top: 32px !important;
			}
			
		}
		
		gap: 64px;
		
		&__content > * {
			margin-bottom: 16px;
		}
		
		&__info {
			display: flex;
			align-items: center;
		}
		
		&__title {
			font-size: 32px;
		}
		
		&__icon {
			margin-bottom: 24px;
		}
		&__img {
			object-position: center;
			border-radius: 7px;
			
			&_house {
				max-height: 450px;
			}
		}
	}
	.location {
		&__title {
			margin-bottom: 16px;
			font-size: 32px;
			@media screen and (max-width: 860px) {
			
			}
		}
		&__block {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
			grid-template-rows: auto;
			gap: 32px;
		}
	}

</style>