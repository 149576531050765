<script setup lang="ts">


import ImgBackground from "~/components/UI/ImgBackground.vue";

const {openSubmitMenu} = useModal()

const scrollBottom = () => {
	window.scroll(0, window.innerHeight - 50)
}

const slideRightAnimation = {
	visibleOnce: {
		x: 0,
		opacity: 1,
		transition: {
			type: 'spring',
			stiffness: 250,
			damping: 25,
			mass: 0.5,
			duration: 300,
			delay: 80
		},
	},
	initial: {
		x: 50,
		opacity: 0,
	}
	
}


</script>

<template>
	<ImgBackground
		src="/main-page/main-banner_desktop.webp"
		height="100svh"
		class="banner"
		alt="all star detailing"
		color="#ffffff"
	>
		<div class="banner__body">
			<MenuTemplate class="banner__menu" text-color="light"/>
			<section class="wrapper">
				
				<header class="banner__content" v-motion v-bind="slideRightAnimation">
					<h2 class="banner__subtitle banner__subtitle_white">Жилой комплекс бизнес-класса</h2>
					<h1 class="banner__title">Ривер Плаза</h1>
					<p class="banner__subtitle banner__subtitle_orange">
						Официальный сайт застройщика
					</p>
					<div class="banner__credit credit" @click="openSubmitMenu('for-call')">
						<div class="credit__title">
							Ипотека от <strong>4.9%</strong>
						</div>
						<div class="credit__btn" >
							<span>Получить консультацию</span>
							<v-icon color="#FAAC37">mdi-chevron-right</v-icon>
						</div>
					</div>
					<v-btn append-icon="mdi-chevron-right" href="/flats" rel="nofollow">Подобрать жилье</v-btn>
				</header>
			
			</section>
			<div class="banner__bottom">
				<v-btn variant="text" icon="true" @click="scrollBottom" color="#fff" aria-label="Name">
					<nuxt-icon name="main-page/arrow" class="banner__arrow" filled>mdi-chevron-down</nuxt-icon>
				</v-btn>
			</div>
		</div>
	</ImgBackground>
</template>

<style lang="scss" scoped>

.credit {
	padding-left: 14px;
	border-left: 3px solid $accent;
	margin: 16px 0;
	cursor: pointer;
	&__title {
		font-size: 24px;
		color: #FFFFFF;
		
		strong {
			color: $accent;
		}
	}
	
	&__btn {
		color: $accent;
	}
	
}

@media screen and (max-width: 760px) {
	.banner {
		&__title {
			font-size: 58px !important;
		}
		&__subtitle {
			
			
			&_white {
				
				font-size: 20px !important;
			}
			
		}
	}
}

@media screen and (max-width: 490px) {
	.banner__title {
		font-size: 48px !important;
	}
	
	.banner__subtitle_orange {
		font-size: 16px !important;
	}
}

.banner {
	&__body {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
	}
	
	&__title {
		color: #FFFFFF;
		font-size: 72px;
		font-family: 'CasperBold', sans-serif;
		letter-spacing: 1.1px;
		margin-top: -8px;
		margin-bottom: -8px;
	}
	
	
	&__subtitle {
		
		
		&_white {
			color: #FFFFFF;
			font-family: 'Casper', sans-serif;
			font-weight: normal;
			font-size: 24px;
		}
		
		&_orange {
			font-size: 20px;
			font-family: 'CasperBold', sans-serif;
			color: $accent;
		}
	}
	
	&__description {
		color: #B4B4B4;
		font-size: 12px;
		font-weight: 400;
		margin-top: 10px;
	}
	
	&__bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
	}
	
	&__menu {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	
	&__arrow {
	
		
	}
}

.btn {
	border: 2px solid #D19D34;
	border-radius: 50px;
	width: fit-content;
	margin-bottom: 8px;
	
	span {
		color: #D19D34;
		font-size: 12px;
		font-weight: 500;
		padding: 6px 6px 6px 12px;
	}
	
	&__icon {
		color: #D19D34;
		padding-right: 6px;
	}
	
	
}

</style>