export const slideBottomAnimation = {
	visibleOnce: {
		y: 0,
		opacity: 1,
		transition: {
			type: 'spring',
			stiffness: 250,
			damping: 25,
			mass: 0.5,
			duration: 300,
			delay: 80
		},
	},
	initial: {
		y: 20,
		opacity: 0,
	}

}