<script setup lang="ts">

</script>

<template>
	<div class="wrapper">
		<VintersectAnimation class="adv block" >
			<div class="adv__info">
				<div class="adv__content">
					<nuxt-icon name="main-page/house" class="adv__icon" filled/>
					<h3 class="adv__title">Жилой комплекс <br> бизнес-класса</h3>
					<p class="adv__text">
						Комплекс расположен в самом центре Сочи и обладает ограниченным
						выбором предложений.
					</p>
					<p class="adv__text">
						Стоимость квадратного метра будет расти
						за счёт постоянного увеличения спроса на
						недвижимость в данном районе.
					</p>
				</div>
				
			</div>
			<v-img class="adv__img img0 adv__img_house" src="/DSC02459.webp" cover alt="ЖК Ривер Плаза Сочи"/>
		</VintersectAnimation>
		
		<VintersectAnimation class="adv block adv_reverse">
			<v-img class="adv__img img1" src="/main-page/pool.webp" cover alt="ЖК Ривер Плаза Сочи"/>
			<div class="adv__info adv__info_center">
				<div class="adv__content">
					<nuxt-icon name="main-page/closr" class="adv__icon" filled/>
					<h3 class="adv__title">Закрытая территория с <br> бассейном</h3>
					<p class="adv__text">
						Проект разработан профессиональными
						ландшафтными дизайнерами: деревья,
						кустарники, цветы подобраны с учетом
						почвенно-климатических особенностей
						местности. Подогреваемый бассейн позволяет взрослым и детям наслаждаться плаваньем круглый год.
					</p>
				</div>
			
			</div>
			
		</VintersectAnimation>
		
		<VintersectAnimation class="adv block">
			<div class="adv__info adv__info_center">
				<div class="adv__content">
					<nuxt-icon name="main-page/parking" class="adv__icon adv__icon_small car" filled/>
					<h3 class="adv__title">Подземный паркинг</h3>
					<p class="adv__text">
						Парковка рассчитана на 53 автомобиля.
					</p>
				</div>
			
			</div>
			<v-img class="adv__img img2" src="/main-page/parking.webp" cover alt="ЖК Ривер Плаза Сочи"/>
		</VintersectAnimation>
		<VintersectAnimation class="adv block adv_reverse">
			<v-img class="adv__img img3" src="/main-page/design.webp" cover alt="ЖК Ривер Плаза Сочи"/>
			<div class="adv__info adv__info_center">
				<div class="adv__content">
					<nuxt-icon name="main-page/design" class="adv__icon" filled/>
					<h3 class="adv__title">Отделка «под ключ»</h3>
					<p class="adv__text">
						Мы разработали современный дизайн чистовой отделки.
						
						Лаконичный стиль и натуральные светлые тона погружают в атмосферу спокойствия и единения с природой.
					</p>
				</div>
			
			</div>
			
		</VintersectAnimation>
	</div>
</template>

<style scoped lang="scss">

	.adv {
		
		@media screen and (max-width: 860px) {
			display: block !important;
			margin-top: 32px !important;
			gap: 0;
			
			&_reverse {
				display: flex !important;
				flex-direction: column-reverse;
			}
			
			&__title {
				font-size: 24px !important;
			}
			
			&__icon {
				margin-bottom: 16px !important;
			}
			
			.img {
				&0, &3 {
					height: 300px !important;
				}
				&1, &2 {
					height: 250px !important;
				}
			
			}
			
			&__img {
				margin-top: 8px;
			}
			
		}
		
		.img0 {
			height: 450px;
		}
		.img3 {
			height: 350px;
		}
		
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 64px;
		
		
		&__content > * {
			margin-bottom: 16px;
		}
		
		&__info_center {
			display: flex;
			align-items: center;
		}
		
		&__title {
			font-size: 32px;
		}
		
		&__icon {
			margin-bottom: 24px;
		}
		&__img {
			object-position: center;
			border-radius: 7px;
			
			
		}
	}

</style>